import {Img} from "@onnit-js/ui/components/image";
import Box from "@onnit-js/ui/components/box/Box";
import Text, {Sup} from "@onnit-js/ui/components/text";
import Button from "@onnit-js/ui/components/button/Button";
import Hero from "@onnit-js/ui/components/module/Hero";
import React from "react";
import { HeroProps } from "../../../interfaces/Hero";
import usePromotionEvent from "../../../hooks/usePromotionEvent";

export default ({ baseUrl, imgParams, position }: HeroProps) => {
    const onClick = usePromotionEvent("Hero - Total HUMAN", position);

    return (
        <Hero
            pb={[6, 6, 6, 8]}
            href="/total-human-offer/"
            image={
                <Img
                    src={`${baseUrl}/total-human.jpg${imgParams}`}
                    alt="Total HUMAN®"
                    placeholder={[2608, 1448]}
                    lazyLoad={false}
                />
            }
            onClick={onClick}
        >
            <Box px={[4, 4, 0]}>
                <Text as="h1" typeStyle="copyDisplay02" fontSize={[4, 4, 5, 5, 6, 6, 6]} mb={3}>
                    SAVE UP TO 30% ON TOTAL HUMAN
                    <Sup variant="reg" />
                </Text>
                <Text as="p" typeStyle="body" color="grays.4" mb={5}>
                    These convenient day &amp; night packs provide immune, cognitive, mood, and overall vitality
                    support.{" "}
                </Text>
                <Button el="a" href="/total-human-offer/" width={1} aria-label="Shop Total HUMAN." onClick={onClick}>
                    SHOP NOW
                </Button>
            </Box>
        </Hero>
    );
};
