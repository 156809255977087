import React from "react";
import { FeatureProps } from "../../../interfaces/Feature";
import Text from "@onnit-js/ui/components/text";
import {Img} from "@onnit-js/ui/components/image";
import {DashLogo} from "@onnit-js/ui/components/logo/OnnitLogo";
import Button from "@onnit-js/ui/components/button/Button";
import Hero from "@onnit-js/ui/components/module/Hero";
import usePromotionEvent from "../../../hooks/usePromotionEvent";

export default ({ baseUrl, imgParams, position }: FeatureProps) => {
    const onClick = usePromotionEvent('Hero - What Is Onnit', position);

    return (
        <Hero
            variant="wideOffset"
            px={4}
            py={[6, 6, 8]}
            before={
                <Text as="h2" fontWeight="heavy" fontSize={5} mb={3}>
                    What is Onnit?
                </Text>
            }
            image={
                <Img
                    src={`${baseUrl}/what-is-onnit.jpg${imgParams}`}
                    alt="woman doing crunches"
                    placeholder={[2368, 1316]}
                />
            }
            onClick={onClick}
        >
            <DashLogo bg="grays.3" mx="auto" mb={4} width="80px" />
            <Text as="p" typeStyle="body" color="grays.4" mb={5} textAlign="center">
                We're athletes, biohackers, professionals, retirees, parents, gamers, fighters, tradespeople,
                and forever students. We're all human, trying to get a little better at what we love every day.
            </Text>
            <Text as="p" typeStyle="body" color="grays.4" mb={5} textAlign="center">
                The human body is our instrument that we play to achieve greatness, and optimizing it for peak
                performance requires the proper nutrition, fitness, and supplementation.
            </Text>
            <Text as="p" typeStyle="body" color="grays.4" mb={5} textAlign="center">
                Onnit provides these foundations to support your human journey.
            </Text>
            <Button width={1} el="a" href="/what-is-onnit" onClick={onClick}>
                Learn More About Onnit
            </Button>
        </Hero>
    );
};
