import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import EmailListSignUp from "./EmailListSignUp";
import Grid from "@onnit-js/ui/components/module/grid/12/Grid";
import ContentfulPage from "../../components/contentful/ContentfulPage";

function ContentfulHomePage() {
    return (
        <ContentfulPage>
            <Grid display={["block", "block", "block", "grid"]} mb={8} px={4}>
                <Box gridColumn="5 /  span 4" textAlign="center">
                    <Text fontWeight="heavy" fontSize={5} mb={4}>
                        Sign Up to Get Onnit
                    </Text>
                    <Text typeStyle="body" color="grays.5" mb={6}>
                        Stay connected and receive early access to special deals, announcements on new formulas,
                        motivational content, stellar recipes, and tips on meeting your goals.
                    </Text>
                    <EmailListSignUp />
                </Box>
            </Grid>
        </ContentfulPage>
    );
}

export default ContentfulHomePage;
