import React from "react";
import Text, {Sup} from "@onnit-js/ui/components/text";
import {Img} from "@onnit-js/ui/components/image";
import Box from "@onnit-js/ui/components/box/Box";
import Button from "@onnit-js/ui/components/button/Button";
import Hero from "@onnit-js/ui/components/module/Hero";
import { FeatureProps } from "../../../interfaces/Feature";
import usePromotionEvent from "../../../hooks/usePromotionEvent";

export default ({ baseUrl, imgParams, position }: FeatureProps) => {
    const onClick = usePromotionEvent("Hero - Alpha BRAIN Black Label - Hero", position);

    return (
        <Hero
            pb={[6, 6, 6, 8]}
            href="/alpha-brain-black-label/"
            image={
                <Img
                    alt="Alpha BRAIN Black Label."
                    src={`${baseUrl}/abbl.jpg${imgParams}`}
                    lazyLoad={false}
                    height={1448}
                    width={2608}
                />
            }
            onClick={onClick}
        >
            <Box px={[4, 4, 0]}>
                <Text as="h1" typeStyle="copyDisplay02" fontSize={[4, 4, 5, 5, 6, 6, 6]} mb={3}>
                    Alpha BRAIN
                    <Sup variant="reg" /> Black Label
                </Text>
                <Text as="p" typeStyle="body" color="grays.4" mb={5}>
                    The premium member of the Alpha BRAIN
                    <Sup variant="reg" /> line, Black Label is a refined formula that promotes mental processing speed
                    and laser focus for extreme productivity during those break-glass-in-case-of-emergency situations.
                    <Sup variant="dagger" />
                </Text>
                <Button
                    el="a"
                    href="/alpha-brain-black-label/"
                    width={1}
                    aria-label="Shop Alpha BRAIN Black Label."
                    onClick={onClick}
                >
                    Shop Now
                </Button>
            </Box>
        </Hero>
    );
};
