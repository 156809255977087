import { FeaturedContentArticle } from "../../../interfaces/FeaturedContentArticle";

export const featuredContentArticles: FeaturedContentArticle[] =
    [
        {
            title: "Kettlebell Swing: The 1 Exercise That Fixes 99 Problems",
            url: "/academy/kettlebell-swing/",
            position: 1,
            image: "kettlebell-swing.jpg",
        },
        {
            title: `The Majesty of Mushrooms: Meet the Shroom Tech<span class="oui-sup">®</span> Family`,
            url: "/academy/shroom-tech-family/",
            position: 2,
            image: "majesty-of-mushrooms.jpg",
        },
        {
            title: "You're Already There",
            url: "/academy/youre-already-there/",
            position: 3,
            image: "youre-already-there.jpg",
        },
        {
            title: "Maple Almond Belgian Waffles",
            url: "/academy/recipes/maple-almond-belgian-waffles/",
            position: 4,
            image: "maple-almond-belgian-waffle.jpg",
        },
        {
            title: `"I Tell Myself I Can, and I Do": Q&A with Tribe Member Nathalie Oulhen`,
            url: "/academy/nathalie-oulhen/",
            position: 5,
            image: "nathalie-oulhen.jpg",
        },
        {
            title: "HydroCore Bag Training For An Unshakeable Body",
            url: "/academy/hydrocore-bag-training-for-an-unshakeable-body/",
            position: 1,
            startDate: "2022-12-04 00:00:00",
            endDate: "2023-01-03 23:59:59",
            image: "1-HydroCoreTraining.jpg",
        },
        {
            title: "The Total Guide to Adaptogenic Supplements and Herbs",
            url: "/academy/adaptogens/",
            position: 2,
            startDate: "2022-12-04 00:00:00",
            endDate: "2023-01-03 23:59:59",
            image: "2-Adaptogens.jpg",
        },
        {
            title: `You Want It To Look Better Than Reality": Preston Maddox's Onnit Story`,
            url: "/academy/preston-maddox/",
            position: 3,
            startDate: "2022-12-04 00:00:00",
            endDate: "2023-01-03 23:59:59",
            image: "3-PrestonMaddox.jpg",
        },
        {
            title: "Collagen Peanut Butter Cups",
            url: "/academy/recipes/collagen-peanut-butter-cups/",
            position: 4,
            startDate: "2022-12-04 00:00:00",
            endDate: "2023-01-03 23:59:59",
            image: "4-PBCup.jpg",
        },
        {
            title: "Your Holiday Survival Guide: Tips To Stay Lean This Season",
            url: "/academy/your-holiday-survival-guide-tips-to-stay-lean-this-season/",
            position: 5,
            startDate: "2022-12-04 00:00:00",
            endDate: "2023-01-03 23:59:59",
            image: "5-HolidaySurvivalGuide.jpg",
        },
        {
            title: "5 Ways To Get Stronger TODAY",
            url: "/academy/5-ways-to-get-stronger-today/",
            position: 1,
            startDate: "2023-01-04 00:00:00",
            endDate: "2023-02-04 00:00:00",
            image: "6-5WaysToGetStronger.jpg",
        },
        {
            title: "Creatine: The Ultimate Natural Muscle Booster",
            url: "/academy/creatine/",
            position: 2,
            startDate: "2023-01-04 00:00:00",
            endDate: "2023-02-04 00:00:00",
            image: "7-CreatineArticle.jpg",
        },
        {
            title: `You Have To Experience Something Challenging": Neen Williams' Onnit Story`,
            url: "/academy/neen-williams-story/",
            position: 3,
            startDate: "2023-01-04 00:00:00",
            endDate: "2023-02-04 00:00:00",
            image: "8-NeenStory.jpg",
        },
        {
            title: "Super Shroom Latte",
            url: "/academy/recipes/super-shroom-latte/",
            position: 4,
            startDate: "2023-01-04 00:00:00",
            endDate: "2023-02-04 00:00:00",
            image: "9-SuperShroomLatte.jpg",
        },
        {
            title: "How To Stick With Your Healthy New Year's Resolutions",
            url: "/academy/revamp-your-new-years-resolutions-for-staying-power-success/",
            position: 5,
            startDate: "2023-01-04 00:00:00",
            endDate: "2023-02-04 00:00:00",
            image: "10-NewYearRsolution.jpg",
        }
    ];