import React from "react";
import Text from "@onnit-js/ui/components/text";
import {Img} from "@onnit-js/ui/components/image";
import Button from "@onnit-js/ui/components/button/Button";
import Feature from "@onnit-js/ui/components/module/Feature";
import { FeatureProps } from "../../../interfaces/Feature";
import usePromotionEvent from "../../../hooks/usePromotionEvent";

export default ({ baseUrl, imgParams, position }: FeatureProps) => {
    const onClick = usePromotionEvent("Feature - Total GUT HEALTH", position);

    return (
        <Feature
            px={4}
            py={[6, 6, 6, 8]}
            href="/total-gut-health/"
            heading={
                <Text as="h2" typeStyle="copyDisplay02" fontSize={[4, 4, 5, 5, 6, 6, 6]}>
                    Total GUT HEALTH&trade; with Probiotics
                </Text>
            }
            image={<Img alt="Total GUT HEALTH™" src={`${baseUrl}/tgh.jpg${imgParams}`} placeholder={[2368, 1040]} />}
            cta={
                <Button
                    el="a"
                    href="/total-gut-health/"
                    width={1}
                    aria-label="Shop Total GUT HEALTH."
                    onClick={onClick}
                >
                    SHOP NOW
                </Button>
            }
            content={
                <Text as="p" typeStyle="body" color="grays.4">
                    Optimize your gut health with this combo of enzymes, probiotics, and prebiotics. Get more out of the
                    good stuff you eat, and pay a smaller price for the bad stuff.
                </Text>
            }
            onClick={onClick}
        />
    );
};
