import React from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import Box from "@onnit-js/ui/components/box/Box";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import usePageData from "../../hooks/usePageData";
import Text from "@onnit-js/ui/components/text";
import EmailListSignUp from "./EmailListSignUp";
import {Card, Cards} from "@onnit-js/ui/components/module/Cards";
import Grid from "@onnit-js/ui/components/module/grid/12/Grid";
import WhatIsOnnit from "./heroes/WhatIsOnnit";
import FeaturedContent from "./heroes/FeaturedContent";
import TotalHumanHero from "./heroes/TotalHuman";
import TotalGutHealthOffer from "./features/TotalGutHealthOffer";
import NewMood from "./features/NewMood";
import AlphaBrainCollection from "./heroes/AlphaBrainCollection";
import AlphaBrainBlackLabel from "./heroes/AlphaBrainBlackLabel";

function FallbackPage() {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();

    if (!pageData || !onnitContext) return null;

    const IMG_URL = onnitContext.url.cdn.static_images + "/homepage/oui";
    const IMG_PARAMS = "?q=40&fm=pjpg&auto=compress,format";

    let position = 1;

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />

                <AlphaBrainBlackLabel baseUrl={IMG_URL} imgParams={IMG_PARAMS} position={position++} />
                <AlphaBrainCollection baseUrl={IMG_URL} imgParams={IMG_PARAMS} position={position++} />
                <TotalHumanHero baseUrl={IMG_URL} imgParams={IMG_PARAMS} position={position++} />

                <Cards
                    px={4}
                    py={[6, 6, 6, 8]}
                    before={
                        <Text as="h2" fontWeight="heavy" fontSize={5} mb={4}>
                            Shop
                        </Text>
                    }
                >
                    <Card
                        justifyContent="center"
                        href="/supplements/"
                        span={3}
                        bgSrc={`${IMG_URL}/card-supplements.jpg`}
                    >
                        <Text as="h3" typeStyle="copyHero02" color="white" fontSize={[5, 5, 5, 7, 7, 7]}>
                            Supplements
                        </Text>
                    </Card>
                    <Card justifyContent="center" href="nutrition" span={3} bgSrc={`${IMG_URL}/card-nutrition.jpg`}>
                        <Text as="h3" typeStyle="copyHero02" color="white" fontSize={[5, 5, 5, 7, 7, 7]}>
                            Nutrition
                        </Text>
                    </Card>
                    <Card justifyContent="center" href="/fitness" span={3} bgSrc={`${IMG_URL}/card-fitness.jpg`}>
                        <Text as="h3" typeStyle="copyHero02" color="white" fontSize={[5, 5, 5, 7, 7, 7]}>
                            Fitness
                        </Text>
                    </Card>
                    <Card justifyContent="center" href="/sale" span={3} bgSrc={`${IMG_URL}/card-on-sale.jpg`}>
                        <Text as="h3" typeStyle="copyHero02" color="white" fontSize={[5, 5, 5, 7, 7, 7]}>
                            On Sale
                        </Text>
                    </Card>
                </Cards>

                {/* Features */}
                <NewMood baseUrl={IMG_URL} imgParams={IMG_PARAMS} position={position++} />
                <TotalGutHealthOffer baseUrl={IMG_URL} imgParams={IMG_PARAMS} position={position++} />

                <WhatIsOnnit baseUrl={IMG_URL} imgParams={IMG_PARAMS} position={position++} />

                <FeaturedContent baseUrl={IMG_URL} imgParams={IMG_PARAMS} />

                <Grid display={["block", "block", "block", "grid"]} mb={8} px={4}>
                    <Box gridColumn="5 /  span 4" textAlign="center">
                        <Text fontWeight="heavy" fontSize={5} mb={4}>
                            Sign Up to Get Onnit
                        </Text>
                        <Text typeStyle="body" color="grays.5" mb={6}>
                            Stay connected and receive early access to special deals, announcements on new formulas,
                            motivational content, stellar recipes, and tips on meeting your goals.
                        </Text>
                        <EmailListSignUp />
                    </Box>
                </Grid>
            </>
        </ThemeProvider>
    );
}

export default FallbackPage;
